<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <dialog-confirm :dialog="dialog" @action2="addCourse" @action="importCourse" @close="dialog.show = false">
        <p>
          Deseja criar uma nova turma ou importar do classroom?
        </p>
    </dialog-confirm>
    <base-card heading="Turmas" :button="button" :dialog="true"  @addModal="dialog.show = !dialog.show">
      <template #customButton>
        <v-btn
          @click="syncWithClassroom"
          depressed
          color="primary"
          class="ml-2"
          >Atualizar pessoas</v-btn>
      </template>
      <datatable-list
          :text="text"
          :headers="headers"
          :sortBy="sortBy"
          :items="items"
          v-if="coursesFetch"
          :showSelect="showSelect"
          :displayActionItems="displayActionItems"
          :tableUser="true"
          :pagination="pagination"
          :loading="!coursesFetch"
          @update="pagination = { ...pagination, ...$event}"
        ></datatable-list>
        <v-skeleton-loader
          v-for="i in 5"
          v-else
          :key="i"
          type="table-heading"
        ></v-skeleton-loader>
    </base-card>
  </v-container>
</template>

<script>
import md5 from '@/libs/md5'
import { mapState, mapMutations } from 'vuex'

export default {
  data: () => ({
    page: {
      title: 'Turmas'
    },
    dialog: {
      show: false,
      title: 'Criar turma',
      close: 'Cancelar',
      confirm2: 'Criar nova turma',
      confirm2Color: 'blue darken-1',
      confirm: 'Importar turma'
    },
    id: '',
    coursesFetch: false,
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Escolas',
        disabled: false,
        to: '/school',
        exactPath: true
      }
    ],
    button: {
      show: false,
      text: 'Turma',
      icon: 'mdi-plus'
    },
    text: {
      searchLabel: 'Pesquisar Turmas',
      emptyLabel: 'Nenhuma turma encontrada',
      addLabel: 'Adicionar Truma',
      addUrl: '/school/add',
      importLabel: 'Importar Turmas',
      importUrl: '/school/add'
    },
    headers: [
      {
        text: 'Nome',
        value: 'name',
        align: 'start',
        sortable: false
      },
      {
        text: 'Sala',
        value: 'room',
        align: 'center',
        sortable: false
      },
      {
        text: 'Ano escolar',
        value: 'school_grade',
        align: 'center',
        sortable: false
      },
      {
        text: 'Etapa de ensino',
        value: 'teaching_stage',
        align: 'center',
        sortable: false
      },
      {
        text: 'Turno',
        value: 'shift',
        align: 'center',
        sortable: false
      }
    ],
    sortBy: '',
    showSelect: false,
    displayActionItems: false,
    items: [],
    pagination: {
      page: 1,
      itemsPerPage: 15,
      prev_page_url: false,
      next_page_url: false
    }
  }),
  watch: {
    'pagination.page' (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getCourses()
      }
    },
    'pagination.itemsPerPage' (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getCourses()
      }
    }
  },
  methods: {
    md5,
    ...mapMutations(['setSelectedCourse', 'setLoading']),
    formatCourse (course) {
      const arr = []
      if (!course.length) return arr
      course.forEach((value, index) => {
        if (value) {
          const obj = {
            avatar: `https://gravatar.com/avatar/${md5(value.metadata.id)}?s=80&d=identicon&r=x`,
            shift: this.formatShift(value.data.shift),
            curricular_components: value.data.curricular_components.map((value, index) => index < 1 ? value.name : ' ' + value.name),
            name: value.data.name,
            room: value.data.room,
            school_grade: value.data.school_grade ? value.data.school_grade.name : '',
            teaching_stage: value.data.teaching_stage ? value.data.teaching_stage.name : '',
            classroom: value.data.classroom.alternateLink ? value.data.classroom.alternateLink : false,
            url: `/course/${this.$route.params.id}/${value.metadata.id}`,
            id: value.metadata.id
          }
          arr.push(obj)
        }
      })
      return arr
    },
    formatShift (shift) {
      switch (shift) {
        case 'MORNING':
          return 'Manhã'
        case 'AFTERNOON':
          return 'Tarde'
        case 'NIGHT':
          return 'Noite'
        case 'FULLTIME':
          return 'Integral'
        case 'MORNING_INTERMEDIATE':
          return 'Intermediário - Manhã'
        case 'AFTERNOON_INTERMEDIATE':
          return 'Intermediário - Tarde'
      }
    },
    buildQuery () {
      const pagination = `?page=${this.pagination.page}&per_page=${this.pagination.itemsPerPage}`
      return pagination
    },
    async getCourses () {
      this.coursesFetch = false
      await this.$axios
        .get(`/organizations/${this.id}/schools/${this.$route.params.id}/courses${this.buildQuery()}`)
        .then(response => {
          this.items = this.formatCourse(response.data.items)
          this.coursesFetch = true
          const pagination = response.data.pagination
          this.pagination = {
            ...this.pagination,
            total: pagination.total,
            page: pagination.current_page,
            itemsPerPage: pagination.per_page
          }
        })
      // get adms
    },
    async getSchoolData (organizationId) {
      try {
        const classResponse = await this.$axios.get(`organizations/${organizationId}/schools/${this.$route.params.id}`)

        if (!classResponse.data) {
          throw classResponse
        }

        this.school = classResponse.data
        this.schoolFetch = true
        // store.dispatch('setDataAuth', { token, classData })
      } catch (err) {
        const msg = 'Erro na conexão, tente novamente'
        this.$store.dispatch('alert', { color: 'red', msg })
      } finally {

      }
    },
    addCourse () {
      this.setSelectedCourse({})
      this.$router.push({ path: `/course/add/${this.$route.params.id}` })
    },
    importCourse () {
      this.$router.push({ path: `/course/import/${this.$route.params.id}` })
    },
    async syncWithClassroom () {
      this.setLoading(true)
      this.$axios.post('/auth/sync-classroom-rosters')
        .then(res => this.handleSuccess(res))
        .catch(err => this.handleErrors(err))
        .finally(() => this.setLoading(false))
    },
    handleErrors (err) {
      this.$store.dispatch('alert', { msg: err.response.data.error, color: 'red' })
    },
    handleSuccess (res) {
      this.$store.dispatch('alert', { msg: res.data.message })
    }

  },
  async mounted () {
    const id = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.id = id
    if (id) {
      await this.getSchoolData(id)
      await this.getCourses()
      this.breadcrumbs.push(
        {
          text: this.school.data.name,
          disabled: false,
          to: `/school/${this.$route.params.id}`
        },
        {
          text: 'Turmas',
          disabled: false,
          to: `/school/course/${this.$route.params.id}`
        }
      )
    } else {
      this.$store.dispatch('alert', {
        color: 'red',
        msg: 'Você precisa selecionar uma organização'
      })
    }
    // this.getClassData()

    this.button.url = '/course/add/' + this.$route.params.id
    // this.button.show = (this.user.level >= 3) // diretores e acima
    this.button.show = await (this.user.level >= 1) // professores e acima
  },
  computed: {
    ...mapState(['user'])
  }
}
</script>
